import React, { Component } from "react";
import "./App.css";
import Menu from "./components/menu/Menu";
import Footer from "./components/footer/Footer";
import ChamadaInicialIndicadores from "./components/chamada-inicial-indicadores/Chamada-inicial-indicadores";
import BlocoInfoDois from "./components/blocoinfodois/Blocoinfodois";
import ListaDoadores from "./components/lista-doadores/Lista-Doadores";
import EvolucaoSemanal from "./components/evolucao-semanal/EvolucaoSemanal"
import DoacaoSetoresHook from "./components/doacao-setores/DoacaoSetoresHook";
import GraficosIndicadores from "./components/graficos-indicadores/Graficos-indicadores";
import TypeDonationsChart from "./components/type-donations-chart/TypeDonationsChart"
import Ranking from "./components/ranking/ranking"
import Imprensa from "./components/imprensa/Imprensa";

import axios from "axios";
import Pesquisa from "./components/pesquisa/Pesquisa";

const headerLabels = [
  "Total",
  "Quem doa",
  "Valor Anunciado",
  "in Dollars",
  "Data",
  "Classificação de quem doa",
  "Setor Empresarial",
  "Classificação da Doação",
  "Causa Principal",
  "Cidade",
  "Estado",
  "Como está sendo doado",
  "Referência",
  "Mês",
  "Link do relatório",
  "Link da imagem de capa"
]

export {headerLabels}
export default class App extends Component {
  state = {
    newData: [""],
    loading: true,
    year: (new Date()).getFullYear(),
    yearSheet: '1APWZhYRFXQopRIqp08xJFWluvkmqPvoOM728N9kJIZk'
  };

  async componentDidMount() {
    this.loadPage('1APWZhYRFXQopRIqp08xJFWluvkmqPvoOM728N9kJIZk')
  }

  async loadPage(page = '', year = 2025) {
    this.setState({loading: true})

    await axios
      .get(
        "https://script.google.com/macros/s/AKfycbx1TX66AN4dTq7LPh0Kv4SMuDlPHOmw6LYtcGR3jmrxIagQ5ZyR70qfBBl-9TxfiqcJyg/exec" + (page ? '?year='+page : '')
      )
      .then((res) => {
        const data = res.data;

        if (data['Doações'] && !Array.isArray(data['Doações'][0])) {
          data['Doações'] = data['Doações'].map(j => {
            return [
              "",
              j["Quem doa"],
              j["Valor Anunciado"],
              j["in Dollars"],
              j["Data"],
              j["Classificação de quem doa"],
              j["Setor Empresarial"],
              j["Classificação da Doação"],
              j["Causa Principal"],
              j["Cidade"],
              j["Estado"],
              j["Como está sendo doado"],
              j["Referência"]
            ];
          });
        }

        console.log(data)
        this.setState({ newData: data, loading: false, year: year, yearSheet: page});
      });
  }

  render() {
    return (
      <div className="App">
        <Menu />
        <div className="yearButtons">
          <a onClick={() => { this.loadPage('', 2022) }} href="javascript:void(0)">
            2022
          </a>
          <a onClick={() => { this.loadPage('1Eoxjn2nZF4ehX5qaYyRsLKmd8q8696G5khixYAlaFSM', 2023) }} href="javascript:void(0)">
            2023
          </a>
          <a onClick={() => { this.loadPage('1md9CY7iciOaBO7xue_fCpUmOX4TdaoKNSKV4OdDbYik', 2024) }} href="javascript:void(0)">
            2024
          </a>
          <a onClick={() => { this.loadPage('1APWZhYRFXQopRIqp08xJFWluvkmqPvoOM728N9kJIZk', 2025) }} href="javascript:void(0)">
            2025
          </a>
        </div>
        {this.state.loading && <div className="masterLoading">
          Carregando...
        </div>}
        <ChamadaInicialIndicadores year={this.state.year} yearSheet={this.state.yearSheet} valor={this.state.newData} />
        <ListaDoadores year={this.state.year} yearSheet={this.state.yearSheet} valor={this.state.newData["Doações"]} />
        <EvolucaoSemanal year={this.state.year} yearSheet={this.state.yearSheet} valor={this.state.newData} />
        <TypeDonationsChart year={this.state.year} yearSheet={this.state.yearSheet} valor={this.state.newData} />
        {/* <DoacaoSetoresHook valor={this.state.newData} /> */}
        {/* <GraficosIndicadores valor={this.state.newData} /> */}
        <Pesquisa year={this.state.year} yearSheet={this.state.yearSheet} sheet={this.state.newData["Doações Brasil"]} />
        <Imprensa year={this.state.year} yearSheet={this.state.yearSheet} valor={this.state.newData["Relatórios"]} capa={true} />
        {/* <Ranking valor={this.state.newData} /> */}
        <BlocoInfoDois year={this.state.year} yearSheet={this.state.yearSheet} />
        <Imprensa year={this.state.year} yearSheet={this.state.yearSheet} valor={this.state.newData["Imprensa"]} />
        <Footer />
      </div>
    );
  }
}
